import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Hero from "../components/Hero"
import Layout from "../layouts"
import { pageMargins, remCalc } from "../styles/utils"
import LetterAnimation from "../animations/LetterAnimation"
import { typography, color } from "../styles/variables"
import ReportContent from "../components/pages/Foreword/ReportContent"
import animationData from "../animations/DESKTOP_MOBILE/01-Intro.json"
import mobileAnimationData from "../animations/DESKTOP_MOBILE/01-Intro_MOBILE.json"
import useIsTablet from "../hooks/useIsTablet"

const StyledForeword = styled.div`
  width: 100%;
  ${pageMargins};
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  @media only screen and (max-width: 640px) {
    flex-direction: column-reverse;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  & .foreword_copy {
    width: 50%;
    position: relative;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
    a {
      color: black;
      cursor: pointer;
    }
    & p {
      margin: 2rem 0;
      font-size: 1.25rem;
      line-height: ${remCalc(30)};
      font-family: ${typography.type.secondary};
      &:first-child {
        margin-top: 0;
      }
      & strong {
        line-height: ${remCalc(33)};

        font-family: ${typography.type.primary};
        font-size: 1.5rem;
        @media only screen and (max-width: 1024px) {
          font-size: 1.25rem;
          line-height: 30px;
        }
        @media only screen and (max-width: 640px) {
          font-size: 18px;
          line-height: 27px;
          font-weight: normal;
          /* font-family: ${typography.type.secondary}; */
        }
      }
    }
  }
  & .ceo_image {
    width: 50%;
    padding: 0 5rem;
    @media only screen and (max-width: 640px) {
      width: 75%;
      padding: 0;
    }
    & p {
      font-size: ${remCalc(18)};
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      padding: 0 2rem;
    }
    @media only screen and (max-width: 640px) {
      padding: 0rem;
      & .ceo-text {
        margin-bottom: 2rem;
        margin-top: 0.2rem;
      }
    }
  }
`

const Foreword = ({ location }) => {
  const isTablet = useIsTablet()
  const data = useStaticQuery(graphql`
    query {
      ceoImage: file(relativePath: { eq: "ceo-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <Hero
        bg="black"
        animationData={animationData}
        mobileAnimationData={mobileAnimationData}
        mobileColors={color.header.foreword}
        location={location.pathname}
      >
        {isTablet ? (
          <h1 className="hero_title">Foreword</h1>
        ) : (
          <LetterAnimation duration={0.7} delay={0.017} initDelay={0.1}>
            <h1 className="hero_title text-animated">
              <span className="line-inner">Foreword</span>
            </h1>
          </LetterAnimation>
        )}
      </Hero>
      <StyledForeword>
        <div className="foreword_copy first-section">
          <p>
            <strong>The role of property goes far beyond the provision of bricks and mortar structures. Property transcends generations, and so, too, does its impact. From contribution to GDP, to creating jobs and social impact in our communities, Landsec recognises that the consequences of the actions we take as an organisation are both far reaching and long lasting.</strong>
          </p>
          <p>At a time when the UK is able to once again look to the future as we collectively start to emerge from the Covid-19 crisis - with vaccines now being administered – it feels increasingly important to reflect on Landsec’s role and ambition in connecting communities, delivering spaces and places of vital importance for economic prosperity, and contributing in a meaningful way to local economies.</p>
          <p>At Landsec, we understand the responsibility that comes as part and parcel of the privilege of being one of the UK’s largest listed commercial property companies, and we’re proud of the contribution we make annually to UK PLC. However, we know that we are part of local communities where there are issues of social inequality, struggling high streets, poor community infrastructure, and, at times, a lack of social cohesion, the role we play in helping to fix these issues is now more important than ever. We remain committed to acting as a force for good; working in partnership with the communities that live, work and socialise in the destinations we create, to deliver tangible benefits for all.</p>
          <p>Being a landlord is no longer about just collecting the rent. It is our belief that we should lower our carbon emissions; provide access to employment and training, especially to individuals who find themselves furthest from the job market; deliver sustainable and inclusive places; create new ways for communities to connect; and ensure all stakeholders in the built environment can benefit from the potential of the towns and cities we call home.</p>
          <p>At the heart of our strategy sit four key pillars - optimise, reimagine, realise and grow - all of which allow us to create and support hundreds of thousands of jobs, generate billions each year in GVA and invest significant capital into the UK. Our values have enabled us to look outside of the construction of buildings and the buying and selling of assets to make a lasting positive difference to the areas where we operate. It’s this ethos that has, for example, allowed us to deliver training courses for serving prisoners in trades from window cleaning to drylining, reducing the reoffending rate from a national average of 50% to 12% for participants in the programme. And a commitment to acting in the interests of all our stakeholders saw us become the first property company in the world to align our climate strategy to science-based targets in 2016.</p>
          <p>As we look to the future, we are ambitious in our desire to build on the progress highlighted in this report. The data we have shared here forms the building blocks of our thinking. These data points, and the powerful testimony of the people featured in these pages, should also act as the steppingstones to a profound shift in the way the custodians and creators of the built environment – Landsec included – work to connect communities, realise potential and create sustainable places.</p>
          <p>
            <a href="https://landsec.com/about" target="_blank">https://landsec.com/about</a>
          </p>
        </div>
        <div className="ceo_image">
          <Img fluid={data.ceoImage.childImageSharp.fluid} />
          <p className="text-xs" style={{ marginTop: "1.5rem" }}>Mark Allan</p>
          <p className="text-xs ceo-text">Chief Executive</p>
        </div>
      </StyledForeword>
      <ReportContent />
    </Layout>
  )
}

export default Foreword
