import React from "react"
import { Link } from "gatsby"
import { remCalc, pageMargins } from "../../styles/utils"
import { typography } from "../../styles/variables"
import styled from "styled-components"

export const StyledItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 640px) {
    & svg {
      width: 2rem;
      height: 2rem;
    }
  }

  & svg {
    transform: ${({ to }) => `rotate(${to ? "-90deg" : "0deg"})`};
  }
`

const WithLink = StyledItem.withComponent(Link)

const Item = ({ children, to = null, scrollElementClass }) => {
  // const scrollElement = () => {
  //   document.querySelector(`.${scrollElementClass}`).scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //     inline: "nearest",
  //   })
  // }
  return to ? (
    <WithLink to={to}>{children}</WithLink>
  ) : (
    <StyledItem className="item" data-id={scrollElementClass}>
      {children}
    </StyledItem>
  )
}

export default Item
