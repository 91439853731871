import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { remCalc, pageMargins } from "../../../styles/utils"
import { typography } from "../../../styles/variables"
import Explore from "../../../images/svgs/Explore"
import Item from "../../List/Item"
import List from "../../List"
import Line from "../../Line"

const StyledReport = styled.div`
  width: 100%;
  ${pageMargins}

  justify-content: space-between;
  display: flex;
  padding-bottom: ${remCalc(104)};
  & .list {
    margin-top: 0;
  }
  & a {
    color: black;
    text-decoration: none;
    border-bottom: 1px solid #a6b6ba;
    &:hover {
      text-decoration: underline;
    }
  }

  & .report_links {
    width: 50%;
    position: relative;
    &-link {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      border-bottom: 1px solid #a6b6ba;
      cursor: pointer;
      @media only screen and (max-width: 640px) {
        padding: 0;
      }
      & p {
        font-size: 1.5rem;
        font-family: ${typography.type.secondary};
      }
      & svg {
        transform: rotate(-90deg);
        @media only screen and (max-width: 640px) {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  & .reports_contents-title {
    font-size: ${remCalc(56)};
    letter-spacing: -1px;
    width: 50%;
    max-width: 28rem;
    margin-right: 1rem;
    position: relative;
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      font-size: 40px;
      line-height: 50px;
    }
    @media only screen and (max-width: 640px) {
      font-size: 24px;
      line-height: 33px;
    }
  }
  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`

const ReportContent = () => {
  return (
    <StyledReport>
      <h3 className="reports_contents-title text-h3">Report contents</h3>
      <List>
        <Item to="/landsec-contribution-overview">
          <p>Landsec contribution overview</p>
          <Explore />
        </Item>
        <Item to="/economic-contribution">
          <p>Total economic contribution</p>
          <Explore />
        </Item>

        <Item to="/covid-19-response">
          <p>Covid-19 response</p>
          <Explore />
        </Item>
        <Item to="/jobs">
          <p>Jobs</p>
          <Explore />
        </Item>
        <Item to="/sustainability">
          <p>Environmental sustainability</p>
          <Explore />
        </Item>
        <Item to="/social-sustainability">
          <p>Social sustainability</p>
          <Explore />
        </Item>
      </List>
    </StyledReport>
  )
}

export default ReportContent
