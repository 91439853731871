import React from "react"
import styled from "styled-components"

const StyledList = styled.div`
  width: 50%;
  position: relative;
  margin-top: 2rem;
  display: ${({ device }) =>
    device === "desktop" || !device ? "inline-block" : "none"};
  & .item {
    border-bottom: 1px solid #a6b6ba;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
  & a {
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
  @media only screen and (max-width: 1024px) {
    display: ${({ device }) =>
      device === "desktop" ? "none" : "inline-block"};
    width: 100%;
    & .item {
      border-bottom: 1px solid #a6b6ba;
      &:last-of-type {
        border-bottom: 1px solid transparent !important;
      }
    }
  }
`

const List = ({ children, device }) => {
  return (
    <StyledList device={device} className="list">
      {children}
    </StyledList>
  )
}

export default List
